<template>
  <!-- modal page-->
  <b-modal
    id="modal-page"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">{{ pageData.title }}</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>

    <div class="px-2 page-modal-content">
      <div v-html="pageData.content"></div>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { BModal, BButton, BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {};
  },
  directives: {
    Ripple,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-page", "#btnShow");
    },
  },
  setup() {},
};
</script>


<style lang="scss">
.page-modal-content img {
  width: 100%;
}
.page-modal-content {
  a {
    color: #22c48b !important;
    &:hover {
      color: #189368!important;
    }
  }
}
</style>

<style lang="scss" scoped>
</style>