<template>
  <!-- modal page-->
  <b-modal
    id="modal-add-sub-account"
    cancel-variant="outline-secondary"
    centered
    hide-footer
    size="lg"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">新增子帳戶</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- contact_name -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="中文名稱"
                rules="required"
              >
                <b-form-group
                  label="中文名稱："
                  label-for="sub_name_cn"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="sub_name_cn"
                    v-model="subAccountData.contact_name"
                    placeholder="輸入中文名稱"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- email -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="電郵"
                rules="required|email"
              >
                <b-form-group
                  label="電郵："
                  class="required"
                  label-for="sub_email"
                  label-cols-md="3"
                >
                  <b-form-input
                    id="sub_email"
                    v-model="subAccountData.email"
                    :state="getValidationState(validationContext)"
                    placeholder="輸入電郵"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <hr />

            <!-- contact_phone -->
            <b-col cols="12" md="6">
              <b-form-group
                label="聯絡電話："
                label-for="sub_contact_phone"
                label-cols-md="3"
              >
                <b-form-input
                  id="sub_contact_phone"
                  v-model="subAccountData.contact_phone"
                  placeholder="輸入聯絡電話"
                />
              </b-form-group>
            </b-col>

            <!-- mobile -->
            <b-col cols="12" md="6">
              <b-form-group
                label="手提電話："
                label-for="sub_mobile"
                label-cols-md="3"
              >
                <b-form-input
                  id="sub_mobile"
                  v-model="subAccountData.mobile"
                  placeholder="輸入手提電話"
                />
              </b-form-group>
            </b-col>
            <hr />

            <!-- address -->
            <!-- <b-col cols="12" md="12">
              <div
                class="
                  d-flex
                  flex-wrap flex-md-nowrap
                  align-items-center
                  full_input
                "
              >
                <label>住宅地址：</label>
                <div class="input_wrap">
                  <b-form-input
                    id="sub_address"
                    v-model="subAccountData.address"
                    placeholder="輸入住宅地址"
                  />
                </div>
              </div>
            </b-col>
            <hr /> -->
            
            <!-- is_active -->
            <b-col cols="12" md="6">
              <b-form-group
                label-for="sub_is_active"
                label-cols-md="3"
                label="狀態"
              >
                <b-form-checkbox
                  style="margin-top: 5px"
                  checked="true"
                  value="1"
                  v-model="subAccountData.is_active"
                  class="custom-control-success"
                  name="check-button"
                  switch
                  inline
                />
                <!-- {{ subAccountData.is_active || 
          (!isNaN(subAccountData.is_active) && subAccountData.is_active > 0)
           ?  1 : 0
           }} -->

                <span style="position: relative; top: -5px">{{
                  subAccountData.is_active ? "啓用" : "禁用"
                }}</span>
              </b-form-group>
            </b-col>
            <hr />
            <!-- password -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="密碼"
                vid="vid_sub_password"
                rules="required|min:6"
              >
                <b-form-group
                  label="密碼："
                  label-for="sub_password"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="sub_password"
                    v-model="subAccountData.password"
                    type="password"
                    :state="getValidationState(validationContext)"
                    placeholder="輸入密碼"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- confirm password -->
            <b-col cols="12" md="6">
              <validation-provider
                #default="validationContext"
                name="確認密碼"
                :rules="`required|min:6|confirmed:vid_sub_password`"
              >
                <b-form-group
                  label="確認密碼："
                  :label-for="`sub_confirm_password`"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    v-model="confirm_p"
                    type="password"
                    :id="`sub_confirm_password`"
                    :state="getValidationState(validationContext)"
                    placeholder="輸入確認密碼"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hideModal"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import axiosIns from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
      confirm_p: "",
      loading: false,
    };
  },
  directives: {
    Ripple,
  },
  mounted() {
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-add-sub-account");
    },

    onSubmit() {
      this.$swal({
        title: `是否確認使用 ${this.$store.state.app.user_info.info.coin_to_open_account} Coins 去新增子帳戶？`,
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.subAccountData.is_active ||
          (!isNaN(this.subAccountData.is_active) &&
            this.subAccountData.is_active > 0)
            ? (this.subAccountData.is_active = 1)
            : (this.subAccountData.is_active = 0);

          axiosIns
            .post("/account", this.subAccountData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                confirmButtonText: "確定",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$store.dispatch("app/fetchCoinsBalance");
              this.hideModal();
              this.subAccountData = {};
              if (this.$route.name == "suppliers-edit") {
                window.location.reload();
              }

              if (this.subAccountData.id == 0) {
                this.subAccountData.id = response.data.id;
                this.redirect(response.data.id);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.hideModal();
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup() {
    const subAccountData = ref({ id: 0, is_active: 1 });

    const resetSubAccountData = () => {
      subAccountData.value = JSON.parse(
        JSON.stringify({ id: 0, is_active: 1 })
      );
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetSubAccountData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      subAccountData,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#modal-add-sub-account {
  .full_input label {
    width: calc(14% - 10px);
  }
}
@media screen and (max-width: 767px){
  #modal-add-sub-account {
  .full_input label {
    width: 100%;
  }
}
}
</style>
