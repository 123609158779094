<template>
  <!-- modal page-->
  <b-modal
    id="modal-edit-moneylender-number"
    cancel-variant="outline-secondary"
    centered
    hide-footer
    size="lg"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">修改放債人號碼</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!--  -->
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="放債人號碼"
                rules="required"
              >
                <b-form-group
                  label="放債人號碼："
                  label-for="moneylender_number"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-input
                    id="moneylender_number"
                    v-model="supplierData.moneylender_number"
                    placeholder="輸入放債人號碼"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="上傳放債人牌照文件"
                rules="required"
              >
                <b-form-group
                  label="上傳放債人牌照文件："
                  label-for="moneylender_number_doc"
                  label-cols-md="3"
                  class="required"
                >
                  <b-form-file
                    v-model="supplierData.file"
                    placeholder="Choose a file or drop it here..."
                  ></b-form-file>
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              提交
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hideModal"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import store from "@/store";
import axiosIns from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormFile,
  BButton,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, onMounted } from "@vue/composition-api";
import vSelect from "vue-select";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BFormFile,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,

      loading: false,
    };
  },
  directives: {
    Ripple,
  },
  mounted() {},
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-edit-moneylender-number");
    },

    onSubmit() {
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          formData.append(
            "moneylender_number",
            this.supplierData.moneylender_number
          );
          formData.append("file", this.supplierData.file);

          axiosIns
            .post("/supplier_change_request/", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                confirmButtonText: "確定",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.hideModal();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  setup() {
    const supplierData = ref({
      moneylender_number: null,
    });

    const resetsupplierData = () => {
      supplierData.value = JSON.parse(
        JSON.stringify({
          moneylender_number: null,
        })
      );
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetsupplierData);

    onMounted(() => {
      supplierData.value.moneylender_number =
        store.state.app &&
        store.state.app.user_info &&
        store.state.app.user_info.userData &&
        store.state.app.user_info.userData.moneylender_number;
    });

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      supplierData,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
#modal-edit-moneylender-number {
  .full_input label {
    width: calc(14% - 10px);
  }
}
@media screen and (max-width: 767px) {
  #modal-edit-moneylender-number {
    .full_input label {
      width: 100%;
    }
  }
}
</style>
