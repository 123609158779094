<template>
  <div class="navbar-container d-flex justify-content-end align-items-center">
    <modal-page :page-data="pageData"></modal-page>
    <modal-tutorial></modal-tutorial>
    <modal-add-sub-account />
    <modal-edit-moneylender-number/>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <!-- Left Col -->
    <!-- <div
      class="bookmark-wrapper colorWhite align-items-center flex-grow-1 d-none d-lg-flex"
    >
      {{
        new Date().getDate() +
        " " +
        monthNames[new Date().getMonth()] +
        " " +
        new Date().getFullYear() +
        " " +
        `( ${dayNames[new Date().getDay()]} )`
      }}
    </div> -->
    <div
      class="d-flex nav-main-content justify-content-between align-items-center"
    >
      <div style="width: 115px"></div>
      <div class="d-none d-md-flex justify-content-start align-items-center">
        <div v-for="page of pageList" :key="page.slug + page.id" class="mr-5">
          <a href="javascript:void(0)" @click="triggerModal(page)">
            <!-- <font-awesome-icon :icon="`fas ${page.icon}`" /> -->
            {{ page.title }}
          </a>
        </div>
        <!-- <a href="javascript:void(0)" v-b-modal.modal-tutorial> 簡單使用教學 </a> -->

        <!-- <b-link
          class=""
          v-if="
            $store.state.app.user_info &&
            $store.state.app.user_info.info.enable_lucky_draw
          "
          to="/luckyWheel"
        >
         
          <font-awesome-icon class="mx-50" icon="fa-solid fa-gifts" />
          <span>大抽獎</span>
        </b-link> -->
      </div>
      <a class="" href="/">
        <img
          style="width: 115px"
          src="~@/assets/images/logo/logo-dark.png"
          alt=""
        />
      </a>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ModalPage from "../modal/ModalPage";
import ModalAddSubAccount from "../modal/ModalAddSubAccount";
import ModalTutorial from "../modal/ModalTutorial";
import ModalEditMoneylenderNumber from "../modal/ModalEditMoneylenderNumber";

export default {
  data() {
    return {
      pageData: {},
      storeName: "",
      storeId: "",
      // monthNames: [
      //   "January",
      //   "February",
      //   "March",
      //   "April",
      //   "May",
      //   "June",
      //   "July",
      //   "August",
      //   "September",
      //   "October",
      //   "November",
      //   "December",
      // ],
      // dayNames: ["SUN", "MON", "TUE", "WED", "THUR", "FRI", "SAT"],
      pageList: [],
      icons: ["fa-money-bill-wave", "fa-question-circle", "fa-phone"],
    };
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
    FeatherIcon,
    ModalPage,
    ModalTutorial,
    ModalAddSubAccount,
    ModalEditMoneylenderNumber
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
    pageData2: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleEditAccount() {
      this.$router.push({ name: "suppliers-edit" });
    },
    handleLogout() {
      //Request server to void the token
      axiosIns.get("/logout");
      localStorage.clear();

      // localStorage.setItem("isWarningShow", 1);
      //Reqired to login page
      //Show the success message
      this.$router.replace("/login").then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully logged out!`,
          },
        });
      });
    },
    getTopNavList() {
      axiosIns.get("/page/list").then((response) => {
        this.pageList = response.data.pages;
      });
    },
    triggerModal(item) {
      this.pageData = { ...item };
      this.$bvModal.show("modal-page");
    },
  },
  computed: {
    ...mapGetters("app", [
      "user_contact_name",
      "user_company_name_cn",
      "user_balance",
      "user_parent",
    ]),
  },
  created() {
    this.getTopNavList();
    this.$store.dispatch("app/fetchCoinsBalance");
  },
  mounted() {
    if (!getUserData()) {
      this.$router.replace("/login").then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Success`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `You have successfully logged out!`,
          },
        });
      });
    }
    // if (this.user_balance < 300) {
    //   if (
    //     (localStorage.getItem("isWarningShow") !== null &&
    //       localStorage.getItem("isWarningShow") > 0) ||
    //     localStorage.getItem("isWarningShow") === null
    //   ) {

    //     localStorage.setItem("isWarningShow", 0);
    //     var html = `<p class="text-justify">你好！客戶的X Data account餘額不足，避免客戶搜尋有誤，客戶想充值或查詢，可以聯絡我們CS,可以即時處理。<p>
    //                           <p>CS主任：何小姐 <a href="tel:6890 0285">6890 0285<a></p>`;

    //     this.$swal({
    //       html: html,
    //       icon: "info",
    //       confirmButtonText: "確定",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //       },
    //       showClass: {
    //         popup: "animate__animated animate__bounceIn",
    //       },
    //       buttonsStyling: false,
    //     });
    //   }
    // }
  },
  watch: {
    pageData2: {
      handler(newVal, oldVal) {
        this.pageData = { ...newVal };
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.header-navbar
  .navbar-container
  ul.navbar-nav
  li
  a.dropdown-user-link
  .user-nav {
  align-items: center;
  float: none;
  flex-direction: row;
}
</style>>
