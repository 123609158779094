export default [
  {
    title: "主頁",
    route: "dashboard",
    icon: "HomeIcon",
    action: "read",
    resource: "Auth",
  }, 
  {
    title: "新增貸款合約(試用版)",
    route: "contracts-create",
    icon: "FileMinusIcon",
    action: "read",
    resource: "Auth",
  }, 
  {
    title: "合同管理",
    route: "contracts-list",
    icon: "FileTextIcon",
    action: "read",
    resource: "Auth",
  },
   {
     title: "會計",
     route: "",
     icon: "ClipboardIcon",
     children: [
       {
         title: "欠款總表",
         route: "report-outstanding-balance",
         action: "read",
         resource: "Auth",
       },
       {
         title: "還款明細表",
         route: "report-repayment-details",
         action: "read",
         resource: "Auth",
       },
       {
         title: "期數過期報表",
         route: "report-overdue-statement",
         action: "read",
         resource: "Auth",
       },
       {
         title: "貸款還清報表",
         route: "report-loan-cleared-statement",
         action: "read",
         resource: "Auth",
       },
       {
         title: "貸款詳細報表",
         route: "report-loan-detail-report",
         action: "read",
         resource: "Auth",
       },
       {
         title: "收款報表",
         route: "report-payment-detail-report",
         action: "read",
         resource: "Auth",
       },
     ]
   },
  {
    title: "款項管理",
    route: "",
    icon: "BookOpenIcon",
    children: [
      {
        title: "收款帳號",
        route: "receive-account-list",
        action: "read",
        resource: "Auth",
        // resource: "repayment",
      },
      {
        title: "還款",
        route: "repayment-list",
        action: "read",
        resource: "Auth",
        // resource: "repayment",
      },
      {
        title: "追款",
        route: "chasing-list",
        action: "read",
        resource: "Auth",
        // resource: "repayment",
      },
    ]
  },
  // {
  //   title: "客戶",
  //   route: "clients-list",
  //   icon: "UsersIcon",
  //   action: "read",
  //   resource: "Auth",
  // },
  
  {
    title: "貸款上傳／更改紀錄",
    route: "loans-list",
    icon: "CreditCardIcon",
    action: "read",
    resource: "Auth",
  },
  // {
  //   title: "利率計算機",
  //   route: "calculator",
  //   icon: "CpuIcon",
  //   action: "read",
  //   resource: "Auth",
  // },  
  // {
  //   title: "做客數值",
  //   route: "suppliers-info",
  //   icon: "ClipboardIcon",
  //   action: "read",
  //   resource: "Auth",
  // },
  // {
  //   title: "信用卡充值",
  //   route: "paypal",
  //   icon: "DollarSignIcon",
  //   action: "read",
  //   resource: "Auth",
  // },
];
