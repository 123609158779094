<template>
  <b-modal
    id="modal-tutorial"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    :ok-disabled="false"
    headerClass="px-3 py-2 border-bottom bgWhite"
    footerClass="px-3 py-2 border-top bgWhite"
  >
    <template #modal-header="{ close }">
      <h3 class="m-0">簡單使用教學</h3>
      <a @click="close()">
        <font-awesome-icon style="font-size: 20px" icon="fas fa-times" />
      </a>
    </template>

    <div class="px-2 page-modal-content">
      <b-carousel
        id="carousel-1"
        style="text-shadow: 0px 0px 2px #000"
        :interval="4000"
        controls
        indicators
        background="#ababab"
        img-width="1024"
        img-height="480"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
      >
        <b-carousel-slide
          img-src="~@/assets/images/banner/xd.jpeg"
        ></b-carousel-slide>
        <b-carousel-slide
          img-src="~@/assets/images/banner/xd1.jpeg"
        ></b-carousel-slide>
        <b-carousel-slide
          img-src="~@/assets/images/banner/xd2.jpeg"
        ></b-carousel-slide>
        <b-carousel-slide
          img-src="~@/assets/images/banner/xd3.jpeg"
        ></b-carousel-slide>
        <b-carousel-slide
          img-src="~@/assets/images/banner/xd4.jpeg"
        ></b-carousel-slide>
      </b-carousel>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="button"
          variant="outline-secondary"
          @click="cancel"
        >
          取消
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCarousel,
  BCarouselSlide,
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  directives: {
    Ripple,
  },

  methods: {
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-tutorial", "#btnShow");
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
  setup() {},
};
</script>


<style lang="scss">
.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
  filter: invert(100%);
}
.carousel-indicators > li {
  background-color: #22c48b;
  border-radius: 50%;
  border: 1px solid transparent;
  margin-right: 5px;
  margin-left: 5px;
  width: 8px;
  height: 8px;
}
</style>

<style lang="scss" scoped>

</style>